//
// A template used for creating single Product pages.
//

import React from 'react';
import { graphql, Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import Modal from 'react-modal';
import { fadeIn } from 'react-animations';
import FlipMove from 'react-flip-move';
import {
  removeHTMLTags,
  loadState,
  saveState,
  prodIsValid,
  generatePostURI,
  encode,
} from '../components/common/functions';
import leafImage from '../images/popup-leaf.png';
import SEO from '../components/seo';
import Layout from '../components/layout';
import {
  CONTAINED,
  fontSz,
  COLOR_GREEN,
  COLOR_BACKGROUND_GRAY,
  MAX_WIDTH,
  screen,
  STREAMS,
  HIDDEN_ITEMS,
  emailRegX,
  phoneNumberRegX,
  Button,
} from '../components/common/variables';
import { compactFlatFilters } from '../../utils/';
import Gallery from '../components/Gallery';
import Tabs from '../components/Tabs';
import MultiStreamsSlider from '../components/pages/SingleProduct/StreamChooser/MultiStreamsSlider';
import SingleStream from '../components/pages/SingleProduct/StreamChooser/SingelStream';
import ProductPicker from '../components/pages/SingleProduct/ProductPicker';
import ProductBuilder from '../components/pages/SingleProduct/ProductBuilder';

const FullWidthBGGRAY = styled.section`
  background-color: ${COLOR_BACKGROUND_GRAY};
`;

const bounceAnimation = keyframes`${fadeIn}`;

const Container = styled.section`
  ${CONTAINED}
  margin-top: 0px;
  padding: 0;
  @media ${screen.medium} {
    padding: 0 35px;
  }
  @media ${screen.largeXL} {
    margin-top: 50px;
  }
  @media ${screen.largeXXL} {
    padding: 0;
  }
  a {
    color: #69ad3f !important;
    border-bottom: 1px solid #69ad3f;
  }
  .spec-sheet {
    padding: 0.6rem 1rem 0.4rem 1rem;
    width: 100%;
    @media ${screen.largeXL} {
      width: 300px;
    }
  }
  .enquire-now {
    padding: 0.6rem 1.7rem 0.4rem 1.7rem;
    @media ${screen.small} {
      width: 100%;
    }
    @media ${screen.largeXL} {
      width: 70%;
    }
  }
  &.product-picker {
    h2 {
      text-align: center;
      margin-bottom: 27px;
    }
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }
  }
  .product-picker-title {
    font-weight: 400;
    font-size: 1.8rem;
    @media ${screen.medium} {
      font-size: 1.5rem;
    }
    @media ${screen.large} {
      font-size: 2rem;
    }
    text-align: center;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
    color: rgb(105, 173, 63);
    i {
      color: #3a3a3a;
    }
    @media ${screen.largeXL} {
      text-align: left;
      margin: auto;
    }
  }
  &.product-picker-mobile {
    display: block;
    @media ${screen.largeXL} {
      display: none;
    }

    select {
      /* display: block !important;
      width: 100%; */
    }
  }
  &.top-info {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  &.stream-to-choose {
    padding: 2rem 0rem;
    h2 {
      text-align: center;
    }
    display: none;
    @media ${screen.largeXL} {
      display: block;
    }
  }
`;

const GalleryWrapper = styled.div`
  width: 100vw;
  @media ${screen.medium} {
    margin: 0 auto;
    width: auto;
  }
  @media ${screen.largeXL} {
    display: block;
    margin: 0;
    flex: 0 0 55%;
  }
  /* max-width: 600px; */
`;

const TopTextContent = styled.div`
  margin: 0 auto;
  width: 100%;
  margin: 1rem 30px;

  @media ${screen.largeXL} {
    flex: 0 0 42%;
    width: unset;
    margin: 5px;
  }
  h1 {
    font-size: 1.8rem;
    margin: 0px 0px 20px 0px;
    font-weight: 400;
    color: #3a3a3a;
    @media ${screen.largeXL} {
      ${fontSz(40)}
    }
  }
  .toptext__button {
    width: 100%;
    transition: background 0.28s ease-out;
    p,
    a {
      padding: 0.3rem 0.6rem;
      font-size: 20px;
      font-weight: 500;
      height: 30px;
      touch-action: none;
      vertical-align: middle;
      text-align: center;
      border-bottom: 0;
      padding: 0;
      margin: 0;
    }
    .button-fill-green {
      width: 100%;
      text-align: center;
      @media ${screen.largeXL} {
        width: auto;
        text-align: left;
      }
    }
    .button-outline {
      width: 100%;
      text-align: center;
      @media ${screen.largeXL} {
        width: auto;
        text-align: left;
      }
    }
    &:first-of-type {
      margin: 20px 0px;
    }
    @media ${screen.medium} {
      margin-top: 20px;
    }
    @media ${screen.largeXL} {
      margin: 0;
      &:first-of-type {
        margin-top: 10px 0px;
      }
    }
  }
  .toptext__button--container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    align-self: center;
    height: 20%;
    @media ${screen.medium} {
      flex-direction: row;
      .toptext__button {
        width: 50%;
        &:first-of-type {
          margin-right: 5px;
        }
        &:last-of-type {
          margin-left: 5px;
        }
      }
    }
    @media ${screen.largeXL} {
      flex-direction: column;
      .toptext__button {
        width: 60%;
        &:first-of-type {
          margin-right: 0px;
        }
        &:last-of-type {
          margin-left: 0px;
        }
      }
    }
  }
  .toptext__desc {
    font-weight: 300;
    color: #808080;
    font-size: 1.1rem;
    p {
      margin-bottom: 10px;
    }
  }
`;

const RelatedProductsContainer = styled.div`
  max-width: ${MAX_WIDTH};
  margin: 60px auto;
  overflow: hidden;
  @media ${screen.largeXL} {
    margin: 100px auto;
  }

  .head-text {
    font-weight: 400;
    font-size: 1.5rem;
    color: ${COLOR_GREEN};
    text-align: center;
    margin: 0 0 15px 0;
    @media ${screen.medium} {
      margin: 0 0 25px 35px;
    }
    @media ${screen.large} {
      text-align: left;
      font-size: 1.55em;
      margin: 0 0 20px 35px;
    }
    @media ${screen.largeXXL} {
      font-size: 1.65em;
      margin: 0 0 25px 0;
    }
  }

  .product-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0 35px;
    position: relative;
    margin: 0 -20px;
    @media ${screen.medium} {
      margin: 0 -30px;
    }
    @media ${screen.largeXXL} {
      padding: 0;
    }

    .each-product {
      width: calc(50% - 40px);
      margin: 0 20px 20px 20px;
      @media ${screen.medium} {
        margin: 0 30px 30px 30px;
        width: calc(33.33% - 60px);
        display: block;
      }
      @media ${screen.largeXL} {
        width: calc(25% - 60px);
      }
    }
    .name {
      font-size: 1rem;
      text-align: center;
      margin-top: 5px;
    }
  }
`;

const ImageContainer = styled.div`
  animation: ${(props) => props.hover && css`0.8s ${bounceAnimation} ease-out`};

  img {
    width: 100%;
  }
`;

class SingleProductTemplate extends React.Component {
  state = {
    modalIsOpen: false,
    email: loadState('specSheetSSScachedEmail') || '',
    name: loadState('specSheetSSScachedName') || '',
    phone: loadState('specSheetSSScachedPhone') || '',
    errors: [],
    hoveredImage: null,
  };

  // handler for download a Spec Sheet form
  downloadButtonForm = (e) => {
    e.preventDefault();
    saveState('specSheetSSScachedEmail', this.state.email);
    saveState('specSheetSSScachedName', this.state.name);
    saveState('specSheetSSScachedPhone', this.state.phone);
    const { acfProduct } = this.props.data.wpProduct;
    const productName = this.props.data.wpProduct.title;

    const inputs = {
      subject: `Spec sheet has been downloaded by ${this.state.name}`,
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      message: `${this.state.name} has downloaded a spec sheet for the ${productName} product.`,
    };

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'Spec Sheet Download', ...inputs }),
    })
      .then(() => {
        this.setState({ modalIsOpen: false });
        saveState(productName, 'downloaded');
      })
      .catch((error) => alert(error));
    window.open(acfProduct.specsSheet.mediaItemUrl, '_blank');
    return;
  };

  // handler for the user already filled/chached an email for downloading a Spec Sheet
  downloadButtonCached = () => {
    const { acfProduct } = this.props.data.wpProduct;
    const productName = this.props.data.wpProduct.title;

    if (
      loadState('specSheetSSScachedEmail') &&
      loadState('specSheetSSScachedName') &&
      loadState('specSheetSSScachedPhone')
    ) {
      if (loadState(productName)) {
        window.open(acfProduct.specsSheet.mediaItemUrl, '_blank');
      } else {
        const inputs = {
          subject: `Spec sheet has been downloaded by ${this.state.name}`,
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          message: `${this.state.name} has downloaded a spec sheet for the ${productName} product.`,
        };
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'Spec Sheet Download', ...inputs }),
        })
          .then(() => {
            this.setState({ modalIsOpen: false });
            saveState(productName, 'downloaded');
          })
          .catch((error) => alert(error));
        window.open(acfProduct.specsSheet.mediaItemUrl, '_blank');
        return;
      }
    } else {
      this.setState({ modalIsOpen: !this.state.modalIsOpen });
    }
  };

  checkValues = () => {
    let newErrors = [];
    if (this.state.email.length < 1 || !emailRegX.test(this.state.email)) {
      newErrors.push('Your email is invalid.');
    }

    if (this.state.name.length < 1) {
      newErrors.push('Your name is invalid.');
    }

    if (
      this.state.phone.length < 1 ||
      !phoneNumberRegX.test(this.state.phone)
    ) {
      newErrors.push('Your phone is invalid.');
    }

    this.setState({
      errors: Array.from(new Set(newErrors)),
    });

    return newErrors.length > 0;
  };

  binBuilder = (productTitle) => {
    // Render bin builder on the page with these Product title
    if (
      productTitle === 'National Park Range Hotham' ||
      productTitle === 'National Park Range Freycinet' ||
      productTitle === 'National Park Range Esperance' ||
      productTitle === 'National Park Range Kakadu' ||
      productTitle === 'National Park Indoor' ||
      productTitle === 'National Park Range Cooloola' ||
      productTitle === 'National Park Range Flinders'
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { title, acfProduct, id } = this.props.data.wpProduct;
    const contactEmail = 'info@sourceseparationsystems.com.au';

    if (
      acfProduct.streamChoices.length > 0 &&
      !acfProduct.streamChoices[0].image
    ) {
      console.error(`[Alert] [Product] ${title} is missing stream_choices[0]`);
      return <p>{title} is missing stream choices image(s)</p>;
    }

    if (!prodIsValid(acfProduct)) {
      console.error(`[Alert] [Product] ${title} is missing main_images`);
      return <p>{title} is missing main images(s)</p>;
    }

    // flat the filters in each product selection record
    acfProduct.productSelector.map((product) => {
      const compactFilters = compactFlatFilters(product.filters);
      product.flattedFilters = compactFilters;
    });

    // Remove Non-breaking spaces from the description.
    acfProduct.desc = acfProduct.desc.replace(/ /g, ' ');

    // Construct streams data
    const originalStreamsData = this.props.data.Streams;
    const allRelatedStreams = originalStreamsData.edges.map((edge) => {
      return {
        slug: edge.node.slug,
        iconURL: edge.node.acfStreams.streamIcon.localFile.publicURL,
      };
    });

    // Convert all streams from array to slug-iconURL dictionary
    const streamsDict = {};

    allRelatedStreams.forEach((stream) => {
      streamsDict[stream.slug] = stream.iconURL;
    });

    // Clean the data structure for streams chooser

    const streamChoices = acfProduct.streamChoices
      .map((stream, index) => ({
        desc: stream.desc,
        fluid:
          stream.image &&
          stream.image.localFile.childImageSharp.gatsbyImageData.images.fallback
            .src,
        streams_group: stream.stream,
        wp_id: stream.stream[0].termTaxonomyId,
      }))
      .filter((streamChoice) => {
        const stream = STREAMS.find((s) => s.id === streamChoice.wp_id);
        return !stream.hidden;
      });

    streamChoices.map((streamChoice) => {
      streamChoice.streams_group.map((stream) => {
        stream.iconURL = streamsDict[stream.slug];
      });
    });

    const _relatedProducts = this.props.data.relatedProducts.edges
      .filter((i) => !HIDDEN_ITEMS.includes(i.node.id))
      .map((i) => i.node)
      .concat(this.props.data.relatedCPProducts.edges.map((i) => i.node));

    const relatedProducts = [];
    for (let i = 0; i < _relatedProducts.length; i++) {
      const RP = _relatedProducts.find(
        (p) => p.id === acfProduct.relatedProducts[i].id
      );
      if (RP != null) relatedProducts.push(RP);
    }

    const pageTitle = (acfProduct.seo && acfProduct.seo.pageTitle) || title;
    const description =
      (acfProduct.seo && acfProduct.seo.description) ||
      removeHTMLTags(acfProduct.desc)
        .match(/(.*?(?:\.|\?|!))(?: |$)/g)
        .map((sen) => sen.trim())
        .slice(0, 2)
        .join(' ');

    const emailValid =
      this.state.email.length === 0 ||
      (this.state.email.length > 1 && emailRegX.test(this.state.email));

    const nameValid =
      this.state.name.length === 0 || this.state.name.length > 1;

    const phoneValid =
      this.state.phone.length === 0 ||
      (this.state.phone.length > 1 && phoneNumberRegX.test(this.state.phone));

    const readyToSubmit =
      this.state.email.length > 1 &&
      emailRegX.test(this.state.email) &&
      this.state.name.length > 1 &&
      this.state.phone.length > 1 &&
      phoneNumberRegX.test(this.state.phone);

    return (
      <Layout>
        <SEO
          title={pageTitle}
          description={description}
          image={
            acfProduct.mainImages.find((i) => i.image && i.image.localFile)
              .image.localFile.childImageSharp.gatsbyImageData.images.fallback
              .src
          }
        />
        {/* {relatedProducts
          .filter(product => {
            if (
              !product.acfProduct.mainImages
                .slice(1)
                .find(i => i.image && i.image.localFile).image ||
              !product.acfProduct.mainImages
                .slice(1)
                .find(i => i.image && i.image.localFile).image.localFile
            ) {
              console.error(
                `[Alert] ${product.title} is missing main_images.slice(1).find(i => i.image && i.image.localFile)`
              );
              return false;
            }
            return true;
          })
          .map((product, i) => (
            <img
              key={i}
              style={{ display: 'none' }}
              alt="Invisible"
              aria-hidden="true"
              src={
                product.acfProduct.mainImages
                  .slice(1)
                  .find(i => i.image && i.image.localFile).image.localFile
                  .childImageSharp.fluid.src
              }
            />
          ))} */}

        <Modal
          style={{
            overlay: {
              position: 'fixed',
              top: '0px',
              left: '0px',
              right: '0px',
              bottom: '0px',
              zIndex: 10,
              backgroundColor: 'rgba(0, 0, 0, 0.87)',
            },
          }}
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onRequestClose={() => this.setState({ modalIsOpen: false })}
          className="specSheetModal"
          contentLabel="Enquire Now Modal"
        >
          <form
            onSubmit={this.downloadButtonForm}
            name="Spec Sheet Download"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <h2
                ref={(subtitle) => (this.subtitle = subtitle)}
                style={{
                  width: '90%',
                  fontWeight: '400',
                  fontSize: 24,
                  color: '#3a3a3a',
                }}
              >
                Fill in your details below and hit download!
              </h2>
            </div>
            <p
              className="closeX"
              onClick={() => this.setState({ modalIsOpen: false })}
              style={{
                flexGrow: 0,
                padding: 5,
                margin: 0,
                fontSize: 50,
                transform: 'rotateZ(45deg)',
                position: 'absolute',
                right: 20,
              }}
            >
              +
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
              }}
            >
              <input type="hidden" name="subject" />
              <input
                style={{
                  padding: 13,
                  margin: '20px 0px',
                  border: nameValid ? 0 : '1px solid rgb(255, 0, 0)',
                  backgroundColor: nameValid ? '#f5f5f5' : 'rgb(255, 228, 228)',
                  borderRadius: 5,
                  color: '#3a3a3a',
                }}
                onChange={({ target }) => {
                  this.checkValues();
                  this.setState({ name: target.value });
                }}
                name="name"
                type="text"
                value={this.state.name}
                placeholder="Your name*"
              />

              <input
                style={{
                  padding: 13,
                  margin: '0 0 20px 0',
                  border: phoneValid ? 0 : '1px solid rgb(255, 0, 0)',
                  backgroundColor: phoneValid
                    ? '#f5f5f5'
                    : 'rgb(255, 228, 228)',
                  borderRadius: 5,
                  color: '#3a3a3a',
                }}
                onChange={({ target }) => {
                  this.checkValues();
                  this.setState({ phone: target.value });
                }}
                name="phone"
                type="text"
                value={this.state.phone}
                placeholder="Your phone number*"
              />

              <input
                style={{
                  padding: 13,
                  margin: '0px 0px',
                  border: emailValid ? 0 : '1px solid rgb(255, 0, 0)',
                  backgroundColor: emailValid
                    ? '#f5f5f5'
                    : 'rgb(255, 228, 228)',
                  borderRadius: 5,
                  color: '#3a3a3a',
                  outline: 0,
                }}
                name="email"
                type="text"
                value={this.state.email}
                onChange={({ target }) => {
                  this.checkValues();
                  this.setState({ email: target.value });
                }}
                placeholder="Your email address*"
              />

              <input type="hidden" name="message" />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'column',
                padding: '5px 0px',
                fontSize: '1rem',
                color: 'red',
              }}
            >
              {!emailValid && <p>Your email is invalid.</p>}
              {!nameValid && <p>Please enter a name.</p>}
              {!phoneValid && <p>Please enter your phone number.</p>}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '30px 0px 70px 0px',
              }}
            >
              <Button
                style={{
                  outline: 0,
                  pointerEvents: readyToSubmit ? 'auto' : 'none',
                  backgroundColor: readyToSubmit
                    ? '#69ad3f'
                    : 'rgba(0, 0, 0, 0.2)',
                  color: readyToSubmit ? 'white' : 'rgba(0, 0, 0, 0.2)',
                  border: 0,
                }}
              >
                Download
              </Button>
            </div>
            <div style={{ overflow: 'hidden' }}>
              <img
                alt="Leaf"
                src={leafImage}
                style={{
                  height: 170,
                  position: 'absolute',
                  bottom: -50,
                  left: -50,
                  overflow: 'hidden',
                }}
              />
            </div>
          </form>
        </Modal>
        <Container className="top-info">
          <GalleryWrapper>
            <Gallery images={acfProduct.mainImages} />
          </GalleryWrapper>
          <TopTextContent>
            {/* Compostable Bin Liners Compost-A-Pak Product have different name on the backend for client */}
            <h1>{id === 2416 ? `Compostable Bin Liners` : title}</h1>
            <div className="toptext">
              <div
                className="toptext__desc"
                dangerouslySetInnerHTML={{
                  __html: acfProduct.desc,
                }}
              />
              <div className="toptext__button--container">
                <div className="toptext__button">
                  <Button
                    className="spec-sheet"
                    onClick={this.downloadButtonCached}
                  >
                    Download a Spec Sheet
                  </Button>
                </div>
                <div className="toptext__button">
                  <a
                    href={`mailto:${contactEmail}?subject=Enquiry about the ${title} Product`}
                  >
                    <Button
                      transparent
                      className="enquire-now"
                      target="_blank noopener"
                    >
                      Enquire Now
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </TopTextContent>
        </Container>
        <FullWidthBGGRAY>
          <Tabs
            desc={acfProduct.detailDesc}
            dimensions={acfProduct.dimensions}
            accessories={acfProduct.accessories}
            customise={acfProduct.customise}
            circular_eco={acfProduct.circularEco}
          />
        </FullWidthBGGRAY>
        <Container className="stream-to-choose">
          {this.binBuilder(title) ? (
            <ProductBuilder />
          ) : acfProduct.streamChoices.length === 1 ? (
            <SingleStream
              image={
                acfProduct.streamChoices[0].image.localFile.childImageSharp
              }
              imagealt={acfProduct.streamChoices[0].image.title}
              desc={acfProduct.streamChoices[0].desc}
              streamName={acfProduct.streamChoices[0].stream[0].name}
            />
          ) : (
            <MultiStreamsSlider streamChoices={streamChoices} />
          )}
        </Container>

        <Container className="product-picker">
          <h2 className="product-picker-title">
            Which <i>{acfProduct.prodpickerName || title}</i> should you choose{' '}
            ?
          </h2>
          <ProductPicker
            productRecords={acfProduct.productSelector}
            streamChoices={streamChoices}
          />
        </Container>
        <Container className="product-picker-mobile">
          <h2 className="product-picker-title">
            Which <i>{acfProduct.prodpickerName || title}</i> should you choose?
          </h2>
          <ProductPicker
            mobile
            productRecords={acfProduct.productSelector}
            streamChoices={streamChoices}
          />
        </Container>
        <RelatedProductsContainer>
          <h2 className="head-text">Related products</h2>
          <ul className="product-list">
            <FlipMove
              typeName={null}
              staggerDelayBy={100}
              enterAnimation="fade"
              leaveAnimation="fade"
            >
              {relatedProducts.length > 0
                ? relatedProducts
                    .filter((i) => !HIDDEN_ITEMS.includes(i.id))
                    .slice(0, 4)
                    .map((product) => {
                      let url;

                      const isRegularProduct = product.nodeType === 'Product';

                      if (isRegularProduct) {
                        const isHovered =
                          !!this.state.hoveredImage &&
                          this.state.hoveredImage.slug === product.slug;

                        if (
                          !this.state.hoveredImage ||
                          this.state.hoveredImage.slug !== product.slug ||
                          !product.acfProduct.mainImages.find(
                            (i) => i.image && i.image.localFile
                          )
                        ) {
                          if (
                            product.acfProduct.mainImages.find(
                              (i) => i.image && i.image.localFile
                            ).image
                          ) {
                            url = product.acfProduct.mainImages.find(
                              (i) => i.image && i.image.localFile
                            ).image.localFile.childImageSharp.gatsbyImageData
                              .images.fallback.src;
                          } else {
                            url = null;
                          }
                        } else {
                          url = product.acfProduct.mainImages
                            .slice(1)
                            .find((i) => i.image && i.image.localFile).image
                            .localFile.childImageSharp.gatsbyImageData.images
                            .fallback.src;
                        }

                        return (
                          <li
                            className="each-product"
                            key={product.slug}
                            onMouseEnter={() => {
                              this.setState({
                                hoveredImage: {
                                  slug: product.slug,
                                },
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({ hoveredImage: null });
                            }}
                          >
                            <Link to={generatePostURI(product)}>
                              <ImageContainer hover={isHovered}>
                                <img src={url} alt="related product display" />
                              </ImageContainer>
                              <h2
                                className="name"
                                style={
                                  this.state.hoveredImage &&
                                  this.state.hoveredImage.slug === product.slug
                                    ? { color: '#808080' }
                                    : { color: '#69ad3f' }
                                }
                              >
                                {product.title}
                              </h2>
                            </Link>
                          </li>
                        );
                      } else {
                        const isHovered =
                          !!this.state.hoveredImage &&
                          this.state.hoveredImage.slug === product.slug;

                        if (
                          !this.state.hoveredImage ||
                          this.state.hoveredImage.slug !== product.slug ||
                          !product.acfCompostAPakProduct.mainImages.find(
                            (i) => i.image && i.image.localFile
                          )
                        ) {
                          if (
                            product.acfCompostAPakProduct.mainImages.find(
                              (i) => i.image && i.image.localFile
                            ).image
                          ) {
                            url = product.acfCompostAPakProduct.mainImages.find(
                              (i) => i.image && i.image.localFile
                            ).image.localFile.childImageSharp.gatsbyImageData
                              .images.fallback.src;
                          } else {
                            url = null;
                          }
                        } else {
                          url = product.acfCompostAPakProduct.mainImages
                            .slice(1)
                            .find((i) => i.image && i.image.localFile).image
                            .localFile.childImageSharp.gatsbyImageData.images
                            .fallback.src;
                        }

                        return (
                          <li
                            className="each-product"
                            key={'x'}
                            onMouseEnter={() => {
                              this.setState({
                                hoveredImage: {
                                  slug: product.slug,
                                },
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({ hoveredImage: null });
                            }}
                          >
                            <Link to={generatePostURI(product)}>
                              <ImageContainer hover={isHovered}>
                                <img src={url} alt="related product display" />
                              </ImageContainer>
                              <h2
                                className="name"
                                style={
                                  this.state.hoveredImage &&
                                  this.state.hoveredImage.slug === product.slug
                                    ? { color: '#808080' }
                                    : { color: '#69ad3f' }
                                }
                              >
                                {product.title}
                              </h2>
                            </Link>
                          </li>
                        );
                      }
                    })
                : null}
            </FlipMove>
          </ul>
        </RelatedProductsContainer>
      </Layout>
    );
  }
}

export default SingleProductTemplate;

// GraphQL query
export const productCatQuery = graphql`
  query ProductQuery(
    $id: String!
    $streams: [String]
    $related_products: [String]
  ) {
    wpProduct(id: { eq: $id }) {
      id
      title
      acfProduct {
        desc
        seo {
          pageTitle
          description
        }
        specsSheet {
          mediaItemUrl
        }
        relatedProducts {
          ... on WpProduct {
            id
          }
          ... on WpCompostAPakProduct {
            id
          }
        }
        catImage {
          productCode
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 900)
              }
            }
          }
        }
        mainImages {
          productCode
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 900)
              }
            }
          }
          video
        }
        detailDesc
        dimensions {
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          dimesionsPCode
          detailVol
          height
          width
          depth
        }
        accessories {
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          description
          header
          productLink {
            ... on WpProduct {
              uri
            }
            ... on WpCompostAPakProduct {
              uri
            }
          }
        }
        customise {
          header
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          description
        }
        circularEco
        streamChoices {
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          desc
          stream {
            termTaxonomyId
            slug
            name
          }
        }
        prodpickerName
        productSelector {
          productCode
          desc
          filters {
            ... on WpProduct_Acfproduct_productSelector_Filters_FilterVolumeLitres {
              filterVolume
            }
            ... on WpProduct_Acfproduct_productSelector_Filters_FilterSize {
              filterSize
            }
            ... on WpProduct_Acfproduct_productSelector_Filters_FilterBaseColor {
              filterBaseColor
            }
            ... on WpProduct_Acfproduct_productSelector_Filters_FilterLidColor {
              filterLidColor
            }
            ... on WpProduct_Acfproduct_productSelector_Filters_FilterStream {
              filterStream {
                slug
              }
            }
            ... on WpProduct_Acfproduct_productSelector_Filters_Quantity {
              filterQuantity
            }
            ... on WpProduct_Acfproduct_productSelector_Filters_FilterAperture {
              filterAperture
            }
            ... on WpProduct_Acfproduct_productSelector_Filters_FilterHood {
              filterHood
            }
            ... on WpProduct_Acfproduct_productSelector_Filters_FilterColor {
              filterColor
            }
            ... on WpProduct_Acfproduct_productSelector_Filters_FilterDesign {
              filterDesign
            }
            ... on WpProduct_Acfproduct_productSelector_Filters_FilterPack {
              filterPack
            }
            ... on WpProduct_Acfproduct_productSelector_Filters_FilterPanels {
              filterPanels
            }
            ... on WpProduct_Acfproduct_productSelector_Filters_FilterPrint {
              filterPrint
            }
          }
        }
      }
    }

    Streams: allWpAcfStream(filter: { id: { in: $streams } }) {
      edges {
        node {
          id
          slug
          acfStreams {
            streamIcon {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }

    relatedProducts: allWpProduct(filter: { id: { in: $related_products } }) {
      edges {
        node {
          id
          slug
          title
          nodeType
          acfProduct {
            mainImages {
              productCode
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }

    relatedCPProducts: allWpCompostAPakProduct(
      filter: { id: { in: $related_products } }
    ) {
      edges {
        node {
          id
          slug
          title
          nodeType
          acfCompostAPakProduct {
            mainImages {
              productCode
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
