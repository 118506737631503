/* eslint-disable array-callback-return */
import React from 'react';
import styled from 'styled-components';
import { screen } from '../../../common/variables';

const Wrapper = styled.div`
  display: none;
  @media ${screen.largeXXL} {
    display: block;
  }
  iframe {
    height: 1610px;
    border: 0;
  }
`;

const ProductBuilder = () => {
  return (
    <Wrapper>
      <iframe
        title="builder"
        width="100%"
        scrolling="no"
        src="https://sourcesep.com/source-separation-product-picker/youchoose"
      />
    </Wrapper>
  );
};

export default ProductBuilder;
